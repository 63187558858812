<template>
    <div>
        <WaveDownBG>
            <slot name="navigation" />
            <Header
                :title="data.contact_title_en"
                :text="data.contact_text_en"
                type="small"
                :actions="actions"
            >
                <template v-slot:right>
                    <div class="text-center">
                        <h4>{{ $t('contact.details') }}</h4>
                        <div class="color-secondary" v-html="$options.filters.nl2br(data.contact_details_en)" />
                    </div>
                </template>
            </Header>
        </WaveDownBG>
    </div>
</template>

<script>
import WaveDownBG from '@/components/bgs/WaveDown.vue';
import Header from '@/components/Header.vue';

export default {
    name: 'Contact',
    components: {
        WaveDownBG,
        Header,
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },

        actions() {
            if (this.data.contact_email) {
                return [{
                    title: this.$t('contact.send_email'),
                    href: `mailto:${this.data.contact_email}`,
                    class: 'shadow-m bg-accent mb-l',
                }];
            }
            return [];
        },
    },
};
</script>
